import Swiper, { Navigation, Pagination } from 'swiper';


export class SwiperSlider {

    constructor(id) {
        this.id = id;
    }

    init(slidesPerView, spaceBetween, autoHeight,centeredSlides, loop, freeMode, renderBullet, breakpoints) {

        const mainSwiper = new Swiper(this.id, {

            modules: [Navigation, Pagination],
            slidesPerView: slidesPerView,
            spaceBetween: spaceBetween,
            lazy: true,
            navigation: {
                nextEl: this.id + ' .swiper-button-next',
                prevEl: this.id + ' .swiper-button-prev',
            },
            autoHeight: autoHeight,
            centeredSlides: centeredSlides,
            loop: loop,
            grabCursor: true,
            freeMode: freeMode,
            pagination: {
                el: this.id + " .swiper-pagination",
                clickable: true,
                renderBullet: renderBullet
            },
            breakpoints: breakpoints
        });
    }
}



