import $ from 'jquery';


export class Medical_findings {

    init () {

// lelet form

        function addInputLelet(index) {

            if (index === 7) {
                alert("Nincs több fájl mező");

            } else {
                const dosc = $('#docs');
                let btn = $('<input>', {
                    id: 'file-upload-lelet' + index,
                    type: 'file',
                    name: 'files[]',
                    accept: 'image/jpeg,image/png,image/gif,application/pdf,application/msword',
                    class: 'custom-file-upload hide'
                });

               /* let beforeElement = $('<label>', {
                    for: 'file-upload-lelet' + index,
                    class: 'custom-file-upload'
                });

                let afterElement = $('<label>', {
                    id: 'file-name-lelet' + index
                });*/

                let icon = $("<i class=\"fa fa-cloud-upload\"></i>");

                btn.on('change', function () {
                    setTimeout(() => {
                        addInputLelet(index + 1);
                    }, 800);

                   /* afterElement.text(this.files[0].name);*/
                });

                btn.appendTo(dosc);
                /*icon.appendTo(beforeElement).append("<span class='openSans'>Fájl Feltöltés</span>");
                beforeElement.insertBefore(btn);
                afterElement.insertAfter(btn);*/
            }
        }

        addInputLelet(1);

        $("#lelet-leadas").on("click", function (e) {

            e.preventDefault();

            const form = $('#lelet-form');
            form.validate({
                groups: {
                    nameGroupuzenet: "id"
                },
                rules: {
                    id: {
                        required: true,
                        number: true
                    },
                    'files' : {
                        required: true
                    }
                },
                messages: {
                    id: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!"
                    },
                    'files' : {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    nameGroup: {
                        required: "Minden mező kitöltése kötelező!"
                    }
                }

            });

            const isValid = form.valid();
            if (isValid) {

                let form_data = new FormData();

                $("#lelet-form").find("input[type=file]").each(function (index, field) {

                    let file = $(this).prop('files')[0];

                    if(file === undefined){
                        /*console.log("undefined");*/
                    }else {
                        form_data.append('files[]', file);
                    }

                });

                form_data.append('event', 'lelet');
                form_data.append('name', form.find("#id").val());

                /* console.log(Array.from(form_data) );*/
                $.ajax({
                    type: 'POST',
                    url: ajaxurl,
                    contentType: false,
                    processData: false,
                    dataType: 'json',
                    data: form_data,
                    beforeSend: function () {
                        $("#message_taj").removeClass("error taj_error")
                        $("#loading-image").show();
                        $("#lelet-leadas").prop('disabled', true);
                    },

                    success: function (response) {


                        if (response.msg == "error_taj"){
                            $("#message_taj").html("Nincs ilyen TAJ-szám a Dokirex rendszerében.").addClass("error taj_error");
                            $("#lelet-leadas").prop('disabled', false);
                            $("#loading-image").hide();

                        } else if(response.msg == "error_file") {
                            $("#file").html("Nem megfelelő fájl formátum!").addClass("error taj_error");
                            $("#lelet-leadas").prop('disabled', false);
                            $("#loading-image").hide();
                        }else {
                            console.log("siker" );
                            $("#loading-image").show();
                            $("#lelet-leadas").prop('disabled', true);

                            $(".showform").fadeTo("slow", 0.00, function () { //fade
                                $(this).slideUp("slow", function () { //slide up
                                    $(this).remove(); //then remove from the DOM
                                    $(".thank-you-text").removeClass("d-none");
                                });
                            });
                        }

                    },
                    error: function (response) {
                        console.log("nem siker" );
                        $("#message_taj").html(response.msg)

                    }

                });
            }


        });

    }
}
