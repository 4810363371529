import $ from 'jquery';
import jqueryValidation from 'jquery-validation';

export class Have_your_say {

    init(){
        /**
         *
         * Form mondaj el véleményét
         *
         * */

        $("#have-your-say").on('click', function (e) {
            e.preventDefault();
            let form = $('#Have_your_say');
            form.validate({
                groups: {
                    nameGroupHave_your_say: "email name privacy_policy"
                },
                rules: {
                    name: {
                        required: true
                    },
                    email: {
                        required: true,
                        email: true
                    },
                    privacy_policy: {
                        required: true
                    }
                },
                messages: {
                    name: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    email: {
                        required: "Minden mező kitöltése kötelező!",
                        email: "Nem megfelelő az email formátum!"
                    },
                    privacy_policy: {
                        required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                    },
                    nameGroupHave_your_say: {
                        required: "Minden mező kitöltése kötelező!"
                    }
                }
            });


            let isValid = form.valid();
            if (isValid) {
                let form_data = new FormData();
                /*let file_data = jQuery('#file-upload').prop('files')[0];*/
                let data = {
                    email: form.find("#email").val(),
                    name: form.find("#name").val(),
                    question1: form.find("#message01").val(),
                    question2: form.find("#message02").val(),
                    question3: form.find("#message03").val(),
                    question4: form.find("#message04").val(),
                };
              /*  form_data.append('file', file_data);*/
                form_data.append('action', 'Have_your_say');
                form_data.append('form_data', JSON.stringify(data));

                $.ajax({
                    type: 'POST',
                    url: ajaxurl,
                    contentType: false,
                    processData: false,
                    data: form_data,
                    beforeSend: function () {
                        $("#panelsStayOpen-collapseHave_your_say #loading-image").show();
                        $("#have-your-say").prop('disabled', true);
                    },
                    success: function (res) {
                        $("#panelsStayOpen-collapseHave_your_say .box").fadeTo("slow", 0.00, function () { //fade
                            $(this).slideUp(1000, function () { //slide up
                                $(this).remove(); //then remove from the DOM
                                $("#panelsStayOpen-collapseHave_your_say .thank-you-text").removeClass("d-none");
                            });
                        });
                        $('html, body').animate({
                            scrollTop: $("#panelsStayOpen-collapseHave_your_say").offset().top + (-40)
                        }, 1500);

                    },
                    error: function (res) {

                        /* $(".valasz-oldal").removeClass("hidden-thank-you");*/
                    }

                });
            }
        });
    }
}
