import {ContactForms} from "./components/Form";
import {SwiperSlider} from './components/Slider';
import {TreatmentsIntroduction} from './components/treatmentsTabs';
import {CollapesControll} from './components/Collapes';
import {Popups} from './components/Popup';
import {Kupon_forms} from './kupon/kupon_forms';
import { Medical_findings } from './components/medicalFindings';
import {Srroll_button} from './components/scrollBtn';

import bootstrap from 'bootstrap';


const Main = {

    init: () => {

        const fix = new Srroll_button();
        fix.init();

        const popup = new Popups();
        popup.init();

        const medical = new Medical_findings();
        medical.init();

        const treatments = new TreatmentsIntroduction();
        treatments.init();

        const collapse = new CollapesControll();
        collapse.init();

        const kupon = new Kupon_forms();
        kupon.init();


        const mainSwiper = new SwiperSlider('#main-swiper');
        mainSwiper.init(1, 0, false, true, false, true,
            function renderBullet(index, className) {
                return '<span class="number ' + className + '">' + (index + 1) + "</span>";
            });

        const swiperTreatments = new SwiperSlider('#Treatments_cards');
        swiperTreatments.init(4.5, 30, true, false, false, false, false,
            {
                320: {
                    slidesPerView: 1.3,
                    autoHeight: false,
                    centeredSlides: false,
                    loop: false,
                    grabCursor: false,
                    freeMode: false,
                    spaceBetween: 40,
                    observer: true
                },
                576: {
                    slidesPerView: 2.3,

                },
                768: {
                    slidesPerView: 3.3,
                    spaceBetween: 40,
                },
                991 :{
                    slidesPerView: 3,
                    spaceBetween: 30,
                }
            },);


        const swiperHowitworks = new SwiperSlider('#how-it-works');
        swiperHowitworks.init(4, 30, true, false, true, false, false,
            {
                320: {
                    slidesPerView: 1.5,
                    autoHeight: false,
                    centeredSlides: false,
                    loop: true,
                    grabCursor: false,
                    freeMode: false,
                    spaceBetween: 50,
                    observer: true
                },
                576: {
                    slidesPerView: 1,

                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                1400: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                }
            },);


        const swiperProspectus = new SwiperSlider('#prospectus');
        swiperProspectus.init(1, 30, true, true, true, true, false,
            false);


        const swiperVideo = new SwiperSlider('#slider-produtos-wrap');
        swiperVideo.init(3, 30, true, true, false, true, false,
            false);


        const forms = new ContactForms();
        forms.init();

    }
};


Main.init();

