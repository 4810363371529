export class CollapesControll {

    init() {

        document.addEventListener("DOMContentLoaded", function(event) {

            const collapseElementList = [].slice.call(document.querySelectorAll('[data-show="true"]'))

            collapseElementList.forEach(element => {
                element.addEventListener('click', function() {
                    this.classList.toggle('show');
                    if (this.classList.contains('show')) {
                        this.textContent = 'Vissza';
                    } else {
                        this.textContent = 'Bővebben';
                    }
                });
            });
        });

    }
}

