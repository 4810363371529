import $ from 'jquery';
import jqueryValidation from 'jquery-validation';

export class Recommend_laser_eye_treatment {

    init() {
        /**
         *
         * Form ajanlas ismerősnek véleményét
         *
         * */

        $("#recommend-laser-eye-treatment").on('click', function (e) {
            e.preventDefault();

            let form = $('#recommend-laser-eye-treatment_form');


            form.validate({
                groups: {
                    nameGrouprecommend_laser_eye_treatment: "your_details_name your_details_phone your_details_email name phone email aszf privacy_policy"
                },
                rules: {
                    your_details_name: {
                        required: true
                    },
                    your_details_phone: {
                        required: true,
                        number: true
                    },
                    your_details_email: {
                        required: true,
                        email: true
                    },
                    name: {
                        required: true
                    },
                    phone: {
                        required: true,
                        number: true
                    },
                    email: {
                        required: true
                    },
                    aszf: {
                        required: true
                    },
                    privacy_policy: {
                        required: true
                    }
                },
                messages: {
                    your_details_name: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    your_details_phone: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!"
                    },
                    your_details_email: {
                        required: "Minden mező kitöltése kötelező!",
                        email: "Nem megfelelő az email formátum!"
                    },
                    name: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    phone: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!"
                    },
                    email: {
                        required: "Minden mező kitöltése kötelező!",
                        email: "Nem megfelelő az email formátum!"
                    },
                    aszf: {
                        required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                    },
                    privacy_policy: {
                        required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                    },
                    nameGrouprecommend_laser_eye_treatment: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                }
            });

            let isValid = form.valid();
            if (isValid) {

                let data = {
                    name: form.find("#name").val(),
                    phone: form.find("#phone").val(),
                    email: form.find("#email").val(),
                    your_details_name: form.find("#your_details_name").val(),
                    your_details_phone: form.find("#your_details_phone").val(),
                    your_details_email: form.find("#your_details_email").val(),
                };

                $.ajax({
                    type: 'POST',
                    url: ajaxurl,
                    dataType: 'json',
                    data: {
                        event: 'Recommend_laser_eye_treatment',
                        data: data
                    },
                    beforeSend: function () {
                        $("#panelsStayOpen-collapseI-recommend-laser-eye-treatment #loading-image").show();
                        $("#recommend-laser-eye-treatment").prop('disabled', true);
                    },
                    success: function (res) {
                        $("#panelsStayOpen-collapseI-recommend-laser-eye-treatment .box").fadeTo("slow", 0.00, function () { //fade
                            $(this).slideUp(1000, function () { //slide up
                                $(this).remove(); //then remove from the DOM
                                $("#panelsStayOpen-collapseI-recommend-laser-eye-treatment .thank-you-text").removeClass("d-none");
                            });
                        });
                        $('html, body').animate({
                            scrollTop: $("#panelsStayOpen-collapseI-recommend-laser-eye-treatment").offset().top + (-40)
                        }, 1500);
                    },
                    error: function (res) {

                        /* $(".valasz-oldal").removeClass("hidden-thank-you");*/
                    }

                });
            }
        });
    }
}




