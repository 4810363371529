import $ from 'jquery';
import jqueryValidation from 'jquery-validation';


export class Register_for_a_free_telephone_consultation {

    init(){

        $('input[type="file"]').change(function (e) {
            const geekss = e.target.files[0].name;
            $("#file-name").text(geekss + ' a kiválasztott fájl');
        });

        /**
         *
         * Form Ingyenes telefonos konzultáció szemműtét alkalmasságról
         *
         * */

        $("#register_for_a_free_telephone_consultation").on('click', function (e) {
            e.preventDefault();


            let form = $('#register_for_a_free_telephone_consultation_form');

            $.validator.addMethod("phone", function(value, element) {
                // A minta: 9 jegyű szám
                return this.optional(element) || /^[1-9][0-9]{8}$/.test(value);
            }, "Kérlek, adj meg egy érvényes magyar telefonszámot (9 számjegy).");

            form.validate({
                groups: {
                    nameGroupRequest_register_for_a_free_telephone_consultation: "name  email phone date age aszf privacy_policy"
                },
                rules: {
                    name: {
                        required: true
                    },
                    email: {
                        required: true
                    },
                    phone: {
                        required: true,
                        number: true,
                        phone: true
                    },
                    date: {
                        required: true
                    },
                    age: {
                        required: true
                    },
                    aszf: {
                        required: true
                    },
                    privacy_policy: {
                        required: true
                    }
                },
                messages: {
                    name: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    email: {
                        required: "Minden mező kitöltése kötelező!",
                        email: "Nem megfelelő az email formátuma!"
                    },
                    phone: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!",
                        phone: "Kérlek, adj meg egy érvényes magyar telefonszámot."
                    },
                    date: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    age: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!"
                    },
                    aszf: {
                        required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                    },
                    privacy_policy: {
                        required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                    },
                    nameGroupRequest_register_for_a_free_telephone_consultation: {
                        required: "Minden mező kitöltése kötelező!"
                    }
                }
            });

            let isValid = form.valid();
            let checkedValues = [];
            let selectedRadioButton = form.find('input[name="radiosurgery"]:checked');
            let selectedTime = form.find('input[name="time"]:checked');

            $('input[name="time"]:checked').each(function() {
                checkedValues.push($(this).val());
            });

            let form_data = new FormData();
            let file_data = jQuery('#file-upload-send').prop('files')[0];
            if (isValid) {

                let data = {
                    phone: form.find("#phone").val(),
                    email: form.find("#email").val(),
                    name: form.find("#name").val(),
                    date: form.find("#date").val(),
                    age: form.find("#age").val(),
                    note: form.find("#comment").val(),
                    surgery: selectedRadioButton.val(),
                    time: checkedValues,
                };

                form_data.append('file', file_data);
                form_data.append('action', 'Request_register_for_a_free_telephone_consultation');
                form_data.append('form_data', JSON.stringify(data));


                $.ajax({
                    type: 'POST',
                    url: ajaxurl,
                    contentType: false,
                    processData: false,
                    data: form_data,
                    beforeSend: function () {
                        $("#panelsStayOpen-collapseRegister_for_a_free_telephone_consultation #loading-image").show();
                        $("#register_for_a_free_telephone_consultation").prop('disabled', true);
                    },
                    success: function (res) {

                        $("#panelsStayOpen-collapseRegister_for_a_free_telephone_consultation .box").fadeTo("slow", 0.00, function () { //fade
                            $(this).slideUp(1000, function () { //slide up
                                $(this).remove(); //then remove from the DOM
                                $("#panelsStayOpen-collapseRegister_for_a_free_telephone_consultation .thank-you-text").removeClass("d-none");
                            });
                        });
                        $('html, body').animate({
                            scrollTop: $("#panelsStayOpen-collapseRegister_for_a_free_telephone_consultation").offset().top + (-40)
                        }, 1500);
                    },
                    error: function (res) {
                        console.log(res );
                        /* $(".valasz-oldal").removeClass("hidden-thank-you");*/
                    }

                });
            }
        });
    }
}


