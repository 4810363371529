import $ from 'jquery';
import jqueryValidation from 'jquery-validation';

export class IhaveaQuestion {





    init(){
        /**
         *
         * Form kérdésem van / üzenetet szeretnék küldeni
         *
         * */

        $("#ihave-a-question").on('click', function (e) {
            e.preventDefault();

            let form = $('#ihave_a_question');

            form.validate({
                groups: {
                    nameGroupihave_a_question: "name email phone privacy_policy"
                },
                rules: {
                    name: {
                        required: true
                    },
                    email: {
                        required: true
                    },
                    phone: {
                        required: true,
                        number:true
                    },
                    privacy_policy: {
                        required: true
                    }
                },
                messages: {
                    email: {
                        required: "Minden mező kitöltése kötelező!",
                        email: "Nem megfelelő az email formátum!"
                    },
                    phone: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!"
                    },
                    name: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    privacy_policy: {
                        required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                    },
                    nameGroupihave_a_question: {
                        required: "Minden mező kitöltése kötelező!"
                    }
                }
            });



            let isValid = form.valid();
            if (isValid) {

                let form_data = new FormData();
                let file_data = jQuery('#file-upload-send').prop('files')[0];
                let data = {
                    name: form.find("#name").val(),
                    email: form.find("#email").val(),
                    phone: form.find("#phone").val(),
                    note: form.find("#message").val()

                };
                form_data.append('file', file_data);
                form_data.append('action', 'Ihave_a_question');
                form_data.append('form_data', JSON.stringify(data));

                $.ajax({
                    type: 'POST',
                    url: ajaxurl,
                    contentType: false,
                    processData: false,
                    data: form_data,
                    beforeSend: function () {
                        $("#panelsStayOpen-collapseIhave-a-question #loading-image").show();
                        $("#ihave-a-question").prop('disabled', true);
                    },
                    success: function (res) {
                        $("#panelsStayOpen-collapseIhave-a-question .box").fadeTo("slow", 0.00, function () { //fade
                            $(this).slideUp(1000, function () { //slide up
                                $(this).remove(); //then remove from the DOM
                                $("#panelsStayOpen-collapseIhave-a-question .thank-you-text").removeClass("d-none");
                            });
                        });
                        $('html, body').animate({
                            scrollTop: $("#panelsStayOpen-collapseIhave-a-question").offset().top + (-40)
                        }, 1500);
                    },
                    error: function (res) {
                        console.log("error" );
                    }

                });
            }
        });
    }
}



