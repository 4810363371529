import {Callbackform} from "../forms/Callback";
import {Have_your_say} from "../forms/Have_your_say";
import {IhaveaQuestion} from "../forms/Ihave_a_question";
import {Request_an_appointment} from "../forms/Request_an_appointment";
import {Register_for_a_laser_eyetest} from "../forms/Register_for_a_laser_eyetest";
import {Recommend_laser_eye_treatment} from "../forms/Recommend_laser_eye_treatment";
import {Register_for_a_free_telephone_consultation} from "../forms/Register_for_a_free_telephone_consultation"


export class ContactForms {

    init() {


        const callback = new Callbackform();
        callback.init();

        const haveyou = new Have_your_say();
        haveyou.init();

        const ihavequestion = new IhaveaQuestion();
        ihavequestion.init();

        const appointment = new Request_an_appointment();
        appointment.init();

        const eyestest = new Register_for_a_laser_eyetest();
        eyestest.init();

        const recommend = new Recommend_laser_eye_treatment();
        recommend.init();

        const request = new Register_for_a_free_telephone_consultation();
        request.init();
    }
}
