export class TreatmentsIntroduction {

    init() {

        const tabEl = document.querySelector('button[data-bs-toggle="tab"]')

        if (tabEl) {
            tabEl.addEventListener('shown.bs.tab', event => {
                event.target // newly activated tab
                event.relatedTarget // previous active tab
            })
        }
    }
}


