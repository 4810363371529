import $ from 'jquery';


export class Srroll_button {


    init() {

        $(window).scroll(function(){
            // Get number of pixels of scroll.
            let pixel = $(window).scrollTop();
            // When the scroll exceeds 300px, give the [fixed-menu] class.
            if(pixel > 115){
                $('.float-cta').addClass('fixed');
            } else {
                $('.float-cta').removeClass('fixed');
            }
        });


    }

}
