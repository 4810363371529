import $ from 'jquery';


export class Popups {

    init() {

        let cooikename = 'competitionCookie';
        let exdays = 7;
        let cvalue = 'false';
        let cstatus = true;
        let cookiestatus = false;

        $('.competitionCloseBox').on("click", function () {
            $('.competitionBox').slideUp('fast');
            $('.competitionClosedBox').slideDown('slow');
            setCookie(cooikename, 'false', exdays);
        });

        $('.competitionOpenBox').on("click", function () {
            $('.competitionClosedBox').slideUp('slow');
            $('.competitionBox').slideDown('slow');
            setCookie(cooikename, 'true', exdays);
        });

        let ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cooikename) == 0) {
                var checker = c.substring(cooikename.length, c.length);
                if (checker == '=true') {
                    //csukva van
                    $('.competitionBox').slideDown('slow');
                    $('.competitionClosedBox').slideUp('slow');
                } else {
                    //nyitva van
                    $('.competitionBox').slideUp('slow');
                    $('.competitionClosedBox').slideDown('slow');
                }
            }
        }


        //ha a cookie status igaz akkor kell létrehozni egyet
        if (cstatus) {
            setCookie(cooikename, cvalue, exdays);
        }


        function setCookie(cname, cvalue, exdays) {
            let d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        function checkCookie(cookiename) {
            let detail = getCookie(cookiename);
            if (detail != "") {
                return true;
            } else {
                return false;
            }
        }

        /*setTimeout(() => {
            console.log("Delayed for 1 second.");
        }, 1000);*/

        document.addEventListener("mouseleave", function (event) {
            if (event.clientY <= 0 || (event.clientY >= window.innerHeight) && !(navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/))) {

                $("#graySpace2").fadeIn(100);
                $(".suitable_test2").fadeIn(100);
                if (/(^|;\s?)suitable_test2=/.test(document.cookie)) {
                    $("#graySpace2").css("display", "none");
                    $(".suitable_test2").css("display", "none");
                }
            }
        }, {once: false});


        /*BSZK cookie*/
        $("#graySpace2,#close_cross2,#suitable_test_pop_up2").on('click', function (e) {
            $("#graySpace2,.suitable_test2").fadeOut(500);
            var expire = new Date();
            expire = new Date(expire.getTime() + 1000 * 60 * 60 * 24 * 365);
            document.cookie = "suitable_test2=true; expires=" + expire;
        });

    }
}
