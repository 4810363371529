import $ from 'jquery';
import jqueryValidation from 'jquery-validation';


export class Register_for_a_laser_eyetest {

    init(){
        /**
         *
         * Form jelentkezés alkalmassági vizsgálatra
         *
         * */

        $("#register-for-a-laser-eyetest").on('click', function (e) {
            e.preventDefault();

            let form = $('#register_for_a_laser_eyetest');


            form.validate({
                groups: {
                    nameGroupRegister_for_a_laser_eyetest: "name request_select email phone date age aszf privacy_policy"
                },
                rules: {
                    name: {
                        required: true
                    },
                    request_select: {
                        required: true
                    },
                    email: {
                        required: true
                    },
                    phone: {
                        required: true,
                        number: true
                    },
                    date: {
                        required: true
                    },
                    age: {
                        required: true
                    },
                    aszf: {
                        required: true
                    },
                    privacy_policy: {
                        required: true
                    }
                },
                messages: {
                    request_select: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    name: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    email: {
                        required: "Minden mező kitöltése kötelező!",
                        email: "Nem megfelelő az email formátuma!"
                    },
                    phone: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!"
                    },
                    date: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    age: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!"
                    },
                    aszf: {
                        required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                    },
                    privacy_policy: {
                        required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                    },
                    nameGroupRegister_for_a_laser_eyetest: {
                        required: "Minden mező kitöltése kötelező!"
                    }
                }
            });

            let isValid = form.valid();
            let selectedRadioButton = form.find('input[name="radiosurgery"]:checked');
            if (isValid) {

                let data = {
                    phone: form.find("#phone").val(),
                    dd_text: form.find("#inputStateRegister-for-a-laser-eyetest option:selected").text(),
                    type: form.find("#inputStateRegister-for-a-laser-eyetest option:selected").val(),
                    email: form.find("#email").val(),
                    name: form.find("#name").val(),
                    date: form.find("#date").val(),
                    age: form.find("#age").val(),
                    note: form.find("#comment").val(),
                    surgery: selectedRadioButton.val()
                };

                $.ajax({
                    type: 'POST',
                    url: ajaxurl,
                    dataType: 'json',
                    data: {
                        action: 'Register_for_a_laser_eyetest',
                        data: data

                    },
                    beforeSend: function () {
                        $("#panelsStayOpen-collapseRegister-for-a-laser-eyetest #loading-image").show();
                        $("#register-for-a-laser-eyetest").prop('disabled', true);
                    },
                    success: function (res) {

                        $("#panelsStayOpen-collapseRegister-for-a-laser-eyetest .box").fadeTo("slow", 0.00, function () { //fade
                            $(this).slideUp(1000, function () { //slide up
                                $(this).remove(); //then remove from the DOM
                                $("#panelsStayOpen-collapseRegister-for-a-laser-eyetest .thank-you-text").removeClass("d-none");
                            });
                        });
                        $('html, body').animate({
                            scrollTop: $("#panelsStayOpen-collapseRegister-for-a-laser-eyetest").offset().top + (-40)
                        }, 1500);
                    },
                    error: function (res) {
                        console.log("error" );
                        /* $(".valasz-oldal").removeClass("hidden-thank-you");*/
                    }

                });
            }
        });
    }
}


