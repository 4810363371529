import $ from 'jquery';
import jqueryValidation from 'jquery-validation';

export class Callbackform {

    init () {

        $("#callback").on('click', function (e) {

            e.preventDefault();

            let form = $('#callback-form');

            form.validate({
                groups: {
                    nameGroupCallback: "aszf privacy_policy name phone_number "
                },
                rules: {
                    name: {
                        required: true
                    },
                    phone_number: {
                        required: true
                    },
                    aszf: {
                        required: true
                    },
                    privacy_policy: {
                        required: true
                    }
                },
                messages: {
                    name: {
                        required: "Minden mező kitöltése kötelező!",
                    },
                    phone_number: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    aszf: {
                        required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                    },
                    privacy_policy: {
                        required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                    },
                    nameGroupCallback: {
                        required: "Minden mező kitöltése kötelező!"
                    }
                }
            });

            let isValid = form.valid();
            if (isValid) {

                let data = {
                    name: form.find("#name").val(),
                    phone_number: form.find("#phone_number").val()
                };

                $.ajax({
                    type: 'POST',
                    url: ajaxurl,
                    dataType: 'json',
                    data: {
                        action: 'Callback',
                        data: data
                    },
                    beforeSend: function () {
                        $("#loading-image").show();
                        $("#callbacks").prop('disabled', true);
                    },
                    success: function (res) {
                        $("#loading-image").hide();
                        $("#panelsStayOpen-collapseCallback .box").fadeTo("slow", 0.00, function () { //fade
                            $(this).slideUp(1000, function () { //slide up
                                $(this).remove(); //then remove from the DOM
                                $("#panelsStayOpen-collapseCallback .thank-you-text").removeClass("d-none");
                            });
                        });
                        $('html, body').animate({
                            scrollTop: $("#panelsStayOpen-Callback").offset().top + (-40)
                        }, 1500);
                    },
                    error: function (res) {

                    }
                });
            }
        });
    }
}


